input, button {
   outline: 0!important;
   border: none;
   box-shadow: none!important;
   &:focus, &:hover {
      outline: 0!important;
      border: none;
      box-shadow: none!important;
   }
}
textarea {
  resize: none;
}


/* input:-webkit-autofill {
  box-shadow: 0 0 0 100px #feeae8 inset!important;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  display: none;
} */




ul {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

a, a:hover {
  text-decoration: none;
}


body {
  font-family: arial;
  font-size: 16px;
  color: black;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  min-height: 100%;
  letter-spacing: 1px;
  overflow-x: hidden;
}
