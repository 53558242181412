@import "../variables";
header{
 nav.navbar {
   //background-color: #ffffff !important;
   //background-color: transparent !important;
   background-color: rgba(#fff,0.88) !important;
   @include respond-below(md) {
     background-color: #fff !important;
     .navbar-nav.ml-5{
       margin-left: 0 !important;
     }
   }
   &.scrolled{
     background-color: rgba(#fff,0.88) !important;
     transition: background-color 200ms linear;
     @include respond-below(md) {
       background-color: #fff !important;
     }
     .navbar-nav {
       .nav-item {
         .dropdown-menu{
           background-color: rgb(255, 255, 255) !important;
           box-shadow: 0px 0 5px 0px #dedede;

         }
       }
     }
   }
   .navbar-brand{
     img{
       max-width: 200px;
       @include respond-below(lg) {
         max-width: 150px;
       }
     }
   }
   .navbar-nav{
     .nav-link{
       font-size: .8rem;
       color: $menu-link;
       font-weight: bold;
       @include respond-below(lg) {
         font-size: .7rem;
       }
     }
     .nav-item{
       .dropdown-menu{
         background-color: transparent !important;
         border: none !important;
         .dropdown-item{
           font-size: .75rem;
           &:focus,&:hover{
             background-color: transparent;
             color: #005aab;
           }
         }
       }
     }
   }

 }

  .social{
    text-align: right;
    ul{
      display: flex;
      flex-direction: row;
      text-align: right;
      justify-content: flex-end;
      align-items: flex-end;
      @include respond-below(sm) {
        align-items: center;
        justify-content: center;
      }
      li{
        width: auto;
        padding-left: 10px;
        a{
          img{
            max-width: 32px;
          }
        }
      }
    }
  }
}