// EXTRAS
@import "mixins";
@import "responsive";
@import "variables";
@import "global";

// COMPONENTS
@import "components/global";
@import "components/menu";

/* // COMPONENTS
@import "components/header";


// PAGES
@import "pages/page";
@import "pages/homepage";



// SPECIALS
@import "xxl";
@import "ios"; */
body {
  @include respond-below(sm) {
    padding-top: 90px;
  }
}

.slider {
  overflow-y: hidden;

  .owl-carousel {
    .item {
      position: relative;

      img {
        width: 100%;
        height: auto;
      }

      .text {
        position: absolute;
        top: 30%;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        text-align: center;
        font-size: 2rem;
        @include respond-below(sm) {
          font-size: 1rem;
        }
      }
    }
  }
}

.basil {
  img.basil {
    max-width: 150px;
  }

  p {
    max-width: 75%;
    font-size: 16px;
    color: rgb(0, 0, 0);
    text-align: center;
    margin: 30px auto;
  }
}

.about {
  margin-top: 45px;
  margin-bottom: 45px;
  color: rgb(0, 0, 0);

  .section-title {
    text-align: center;

    img {
      max-width: 50px;
      margin-bottom: 10px;
    }

    h1 {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  .section-text {
    text-align: center;
    margin: auto;

    p {
      font-size: 20px;
      font-weight: bold;
      max-width: 70%;
      margin: 10px auto;
      @include respond-below(sm) {
        width: 100%;
        max-width: 100%;
      }
    }

    .light-paragraf {
      font-weight: 400;
      max-width: 80%;
      margin: 10px auto;
      font-size: 17px;
    }
  }
}

.separate {
  background-color: #4db848;
  margin-top: 45px;
  color: rgb(255, 255, 255);
  padding-bottom: 60px;

  .section-title {
    text-align: center;
    margin: 30px 0 30px 0;

    img {
      max-width: 50px;
      margin-bottom: 10px;
    }

    h1 {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  .section-text {
    text-align: center;
    margin: auto;

    p {
      font-size: 16px;
      max-width: 90%;
      margin: 10px auto;
    }
  }
}

.about-boxes {
  &.box-1 {
    background-color: #ededed;
    color: #000;

    .box-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include respond-below(sm) {
        flex-direction: column;
      }

      .text {
        width: 60%;
        padding-right: 10%;
        @include respond-below(sm) {
          width: 100%;
          text-align: center;
        }

        h2 {
          font-size: 1.9rem;
          margin-bottom: 1rem;
        }

        p {
          font-size: 0.9rem;
          margin-top: 1.5rem;
        }
      }

      .image {
        width: 40%;
        @include respond-below(sm) {
          width: 100%;
        }

        img {
          width: 100%;
        }
      }

    }
  }

  &.box-2 {
    position: relative;
    @include respond-below(lg) {
      background-color: #f57a08;
    }

    .box-wrap {
      background-color: #f57a08;
      display: flex;
      flex-direction: row;
      align-items: center;
      @include respond-below(sm) {
        flex-direction: column-reverse;
      }

      .orange-bg {
        background-color: #f57a08;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      .text {
        position: relative;
        width: 40%;
        background-color: #f57a08;
        color: #fff;
        height: 100%;
        padding-left: 4%;
        @include respond-below(sm) {
          width: 100%;
          text-align: center;
          padding-left: 0;
        }

        h2 {
          font-size: 1.9rem;
          margin-bottom: 1rem;
        }

        p {
          font-size: 0.9rem;
          margin-top: 1.5rem;
        }
      }

      .image {
        width: 60%;
        @include respond-below(sm) {
          width: 100%;
        }

        img {
          width: 100%;
        }
      }

    }

  }

  &.box-3 {
    background-color: #fff;
    color: #000;

    .box-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include respond-below(sm) {
        flex-direction: column;
      }

      .text {
        width: 60%;
        padding-right: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include respond-below(sm) {
          width: 100%;
        }

        .text-wrap {
          width: 60%;
          @include respond-below(xs) {
            width: 100%;
            text-align: center;
          }

          h2 {
            font-size: 1.9rem;
            margin-bottom: 1rem;
          }

          p {
            font-size: 0.9rem;
            margin-top: 1.5rem;
          }
        }

        .tomato {
          width: 40%;
          padding: 0 5%;
          @include respond-below(xs) {
            display: none;
          }

          img {
            width: 100%;
          }
        }

      }

      .image {
        width: 40%;
        @include respond-below(sm) {
          width: 100%;
        }

        img {
          width: 100%;
        }
      }

    }
  }
}

.services {
  .service-description {
    background-color: #d3cbc0;
    color: #000;
    padding: 30px 0;

    h3 {
      color: #fff;
      text-align: center;
      margin-bottom: 2rem;
    }

    .service-boxes {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      @include respond-below(sm) {
        flex-direction: column;
      }

      .box {
        padding: 0 15px;

        .box-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          margin-bottom: 1rem;

          img {

          }

          h4 {
            font-size: 1.2rem;
            margin: 0 0 0 5px;
          }
        }

        .box-text {
          p {
            font-size: .8rem;
          }
        }
      }

      .box-1 {
        width: 30%;
        @include respond-below(sm) {
          width: 100%;
        }
      }

      .box-2 {
        width: 40%;
        @include respond-below(sm) {
          width: 100%;
        }
      }

      .box-3 {
        width: 30%;
        @include respond-below(sm) {
          width: 100%;
        }
      }
    }
  }
}

.quality {
  margin-top: 10px;
  text-align: center;

  .basil {
    margin: 15px 0;
    text-align: center;
    max-width: 150px;
  }

  .text {
    h4 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }

    p {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .hr {
    margin: 30px auto;
    height: 1px;
    background-color: #000;
    width: 80%;
    box-shadow: 0px -1px #d5d4d4;
  }

  .half-text-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    text-align: left;
    @include respond-below(sm) {
      flex-direction: column;
    }

    p {
      width: 48%;
      margin-right: 2%;
      @include respond-below(sm) {
        margin-right: 0;
        width: 100%;
      }

      &:last-child {
        margin-left: 2%;
        margin-right: 0;
        @include respond-below(sm) {
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }

}

.referances {
  background-color: #699501;
  color: #fff;

  .container {
    padding-top: 30px;
    padding-bottom: 30px;

    h4 {
      text-align: center;
      font-size: 1.75rem;
      margin-bottom: 15px;
    }

    p {
      text-align: center;
      font-size: 1rem;
      margin-bottom: 0;
    }
  }

}

.suppliers {
  background-color: #424242;
  color: #fff;

  .container {
    padding-top: 30px;
    padding-bottom: 30px;

    h4 {
      text-align: center;
      font-size: 1.75rem;
      margin-bottom: 15px;
    }

    p {
      text-align: center;
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
}

.footer {
  padding-top: 30px;
  padding-bottom: 15px;

  .logo {
    width: 100%;
    text-align: center;

    img {
      max-width: 200px;
      margin: auto;
    }
  }

  .social {
    text-align: right;

    ul {
      display: flex;
      flex-direction: row;
      text-align: right;
      justify-content: flex-end;
      align-items: flex-end;
      @include respond-below(sm) {
        align-items: center;
        justify-content: center;
      }

      li {
        width: auto;
        padding-left: 10px;

        a {
          img {
            max-width: 32px;
          }
        }
      }
    }
  }
}

.footer-copy {
  background-color: #222222;
  color: #fff;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 9;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include respond-below(sm) {
      flex-direction: column;
    }

    p {
      font-size: 0.8rem;
      padding-right: 30px;
      text-align: right;
      margin: 0;

      &:first-child {
        text-align: left;
      }

      @include respond-below(sm) {
        text-align: center;
      }

    }
  }


}

.page {
  margin-bottom: 50px;

  &-top {
    img {
      width: 100%;
      height: auto;
    }
  }

  &-detail {
    margin: 30px 0 100px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @include respond-below(sm) {
      flex-direction: column-reverse;
    }

    .left-side {
      width: 30%;
      padding-right: 30px;
      @include respond-below(sm) {
        width: 100%;
        padding: 5px 10px;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .right-side {
      width: 70%;
      @include respond-below(sm) {
        width: 100%;
        padding: 5px 10px;
      }

      .page-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;

        h2 {
          color: #005aab;
          font-size: 1.75rem;
          margin: 0;
        }

        img {
          max-width: 100%;
          height: auto;
          width: 50px;
        }
      }

      .page-text {
        .text-section {
          border-top: 1px dashed #737171;
          padding-top: 30px;
          margin-bottom: 30px;

          h4 {
            color: #000;
            font-size: 1.25rem;
            margin: 0 0 10px 0;
            padding: 0;
          }

          p {
            font-size: .8rem;

            a {
              color: #005aab;
            }
          }
        }

      }

    }
  }

  .contact-detail {
    padding-top: 30px;
    padding-bottom: 30px;

    h4 {
      text-align: center;
      font-size: 1.75rem;
      margin-bottom: 15px;
    }

    p {
      text-align: center;
      font-size: 1rem;
      margin-bottom: 0;
    }

    .contact-form-area {
      background-color: #f3f3f3;
      margin-top: 30px;

      iframe {
        width: 100%;
        height: 450px;
        @include respond-below(xs) {
          height: 250px;
        }
      }

      form {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        @include respond-below(sm) {
          flex-direction: column;
          margin-top: 30px;
        }

        ::placeholder {
          color: #e2e2e2;
        }

        div {
          width: 50%;
          padding: 15px;
          @include respond-below(sm) {
            width: 100%;
            padding-bottom: 0;
            padding-top: 0;
          }
        }

        .input {
          padding: 15px;
          border: 1px solid #bcbcbc;
          border-radius: 0;
          margin-bottom: 10px;
          width: 100%;
        }

        .textarea {
          padding: 15px;
          height: 122px;
          width: 100%;
        }

        button {
          background-color: #89b381;
          color: #fff;
          padding: 15px;
          text-transform: uppercase;
          @include respond-below(sm) {
            width: 100%;
            margin-bottom: 15px;
          }
        }
      }
    }

  }

  .certificates {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include respond-below(sm) {
      flex-wrap: wrap;
    }

    .img-wrap {
      padding: 0 10px;
      @include respond-below(sm) {
        width: 33%;
      }
      @include respond-below(xs) {
        width: 50%;
      }

      img {
        max-width: 100%;
      }

      p {
        text-align: left;
        font-size: 0.5rem;
        padding-top: 5px;
        @include respond-below(sm) {
          text-align: center;
          min-height: 40px;
        }
      }
    }
  }
}

.products-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  a{
    color: #000;
    margin: 0 2px;
    p{
      margin: 5px 0;
    }
  }
}