@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');
body{
  font-family: 'Raleway', sans-serif;
}
.img-fluid{
  max-width: 100%;
  height: auto;
}
a{
  text-decoration: none;
  &:hover{
    text-decoration: none;
    color: inherit;
  }
}